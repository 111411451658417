import logo from './logo.png';
import  '../styles/contacts.scss';

const Contacts =() => {
    return (
        <div className="contact">
        <img src={logo} className="contact-logo" alt="logo" />
        </div>
     );
}

export default Contacts;