import './styles/App.css';
import AnimCat from './components/animcat';
import Contacts from './components/contacts';
import Comments from './components/comments';
import Burst from './components/burst';
import React, { useEffect } from 'react';

function App() {
  useEffect(() => {
    document.title = 'Diftah';
  }, []);
  return (
    <div className="App">
        <Contacts />
        <Burst duration={1000} />
          <header className="App-header">
            <AnimCat />
                <p></p>
            <Comments />
            </header>
    </div>
  );
}

export default App;
