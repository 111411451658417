import  '../styles/comments.scss';

const Comments =() => {
    return (
        <div className="contact">
            Too many mouses attacked us, so we are on rebuilding.
        </div>
     );
}

export default Comments;